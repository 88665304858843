.modal {
    &.show {
        display: block;
    }
}

.login-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn $timing cubic-bezier(0,.6,.6,1);
    z-index: 20;
}

.login-modal {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: $radius5;
    .header,
    .footer {
        padding: 5px 15px 10px;
        display: flex;
    }

    .header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: $red;
        background: $white;
    }

    .footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
        background: $white;
    }

    .body {
        position: relative;
        padding: 20px 10px;
        .formwrapper {
            margin-bottom: 0;
        }
    }
}
