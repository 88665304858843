.pagination {
    .page-item {
        margin-right: 5px;
        .page-link {
            min-width: 30px;
            color: $dark-grey;
            @include font-size(14px);
            border-radius: $radius3;
            background: $light-grey;
            border: none;
            padding: 11px 15px 9px;
            text-align: center;
            @include transition(background $timing);
            &:hover {
                background: $medium-grey;
                text-decoration: underline;
            }
        }
        &.active {
            .page-link {
                background: $blue;
                color: $white;
            }
        }
    }
}