.sales-quickorder-widget {
    margin-top: 40px;
    .form-control {
        min-height: 46px;
        height: 46px;
        border-radius: $radius5;
        border: 1px solid $dark-grey;
    }
    .heading {
        padding: 0 15px;
        @include font-size(14px);
        font-family: $secundary-font;
        line-height: 16px;
        margin-bottom: 15px;
    }
    .quickorder-results {
        .result {
            background: $light-grey;
            height: 68px;
            border-radius: $radius5;
            padding: 0 15px;
            margin: 0 0 5px 0;
            @include font-size(14px);
            line-height: 20px;
            align-items: center;
            border: 1px solid transparent;
            &.search {
                border: 1px solid $light-grey;
                background: $white;
            }
            .orderable {
                @include font-size(20px);
                line-height: 15px;
            }
            .grossprice {
                font-family: $secundary-font;
                @include font-size(18px);
                .loader {
                    margin-top: -16px;
                }
            }
            .search-dropdown {
                border: 1px solid $medium-grey;
                background: $white;
                padding: 0;
                margin-top: 5px;
                position: absolute;
                width: 100%;
                @include box-shadow-4;
                border-radius: $radius5;
                ul {
                    margin-bottom: 0;
                    li {
                        cursor: pointer;
                        padding: 10px;
                        &:hover {
                            background: $light-grey;
                        }
                    }
                }
            }
        }
    }
    @include devices(tablet) {
        .quickorder-results {
            .result {
                height: auto;
                padding: 15px;
                margin: 0 0 10px 0;
                .quantity {
                    .btn {
                        width: 100%;
                    }
                }
            }
        }
    }
    @include devices(mobile) {
        .heading {
            display: none;
        }
        .quickorder-results {
            .quantity {
                order: 1;
            }
        }
    }
}
