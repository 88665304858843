.left {
    background: $light-grey;
    border-radius: $radius3;
    padding: 10px 20px;
    .mod_navigation {
        .nav {
            li {
                color: $black;
                @include font-size(15px);
                line-height: 35px;
                &.active {
                    font-family: $secundary-font;
                }
                a {
                    color: $black;
                }
            }
        }
    }
}

.nav {
    flex-direction: column;
}

.sales-navigation {
    display: inline-block;
    @include devices(tablet) {
        display: block;
    }
    .nav {
        flex-direction: row;
        justify-content: flex-end;
        li {
            a,
            span {
                color: $white; 
                @include font-size(14px);
                display: block;
                width: max-content;
                display: block;
                position: relative;
                &:after {
                    content: '';
                    height: 2px;
                    width: 100%;
                    display: block;
                    position: absolute;
                    top: 25px;
                    background: $blue;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
        @include devices(tablet) {
            flex-direction: column;
            li {
                margin-left: 0;
                padding: 15px;
                a,
                span {
                    @include font-size(18px);
                    font-family: $secundary-font;
                }
            }
        }
    }
}

.spares-navigation {
    .nav {
        flex-direction: row;
        justify-content: flex-end;
        li {
            margin-left: 30px;
            a,
            span {
                color: $white;
                font-family: $secundary-font;
                @include font-size(14px);
                display: block;
                position: relative;
                &:hover {
                    text-decoration: none;
                    width: max-content;
                    &:after {
                        content: '';
                        height: 2px;
                        width: 100%;
                        display: block;
                        position: absolute;
                        top: 25px;
                        background: $blue;
                    }
                }
            }
        }
        @include devices(tablet) {
            flex-direction: column;
            margin-top: 60px;
            li {
                margin-left: 0;
                padding: 15px;
                a,
                span {
                    @include font-size(18px);
                }
            }
        }
    }
}

@include devices(tablet) {
    .main-menu {
        position: absolute;
        top: 0;
        left: -100%;
        @include gradient(-45deg, $gradient-red-blue);
        width: 100%;
        height: 100%;
        flex: unset;
        max-width: unset;
        z-index: 2000;
        padding: 15px;
        @include transition(left $timing);
        &.show {
            left: 0;
        }
    }

    #catalog_navigation {
        margin-top: 60px;
        padding: 0;
        .nav {
            .nav-item {
                .nav-link {
                    @include font-size(18px);
                    color: $white;
                    padding: 15px;
                    font-family: $secundary-font;
                }
                .dropdown-menu {
                    border: none;
                    margin: 0 0 0 15px;
                    padding: 0;
                    .nav-item {
                        color: $white;
                    }
                }
            }
        }
    }
}

.hamburger {
    display: none;
    @include devices(tablet) {
        display: block;
    }
    position: absolute;
    right: 15px;
    top: 95px;
    height: 15px;
    width: 27px;
    z-index: 2001;
    cursor: pointer;
    @include transition(all 0.2s ease-in-out);
    .bar-1, .bar-2, .bar-3 {
        position: absolute;
        left: 0;
        top: 0;
        width: 27px;
        height: 3px;
        background-color: $white;
        @include transition(all 400ms cubic-bezier(.84,.06,.52,1.8));
    }
    
    .bar-1 {
      transform: translateY(-8px);
      animation-delay: 100ms;
      &.active {
        transform: rotate(40deg);
      }
    }

    .bar-2 {
        &.active {
            opacity: 0;
        }
    }
    
    .bar-3 {
      transform: translateY(8px);
      animation-delay: 250ms;
      &.active {
        transform: rotate(-40deg);
      }
    }
}