.toggler {
    @include font-size(13px);
    display: flex;
    align-items: center;
    justify-content: center;
    .switch {
        position: relative;
        display: inline-block;
        width: 38px;
        height: 22px;
        margin: 0;
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        input:checked + .slider {
            background-color: $blue;
        }
        
        input:focus + .slider {
            box-shadow: 0 0 1px $blue;
        }
        
        input:checked + .slider:before {
            transform: translateX(16px);
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $medium-grey;
            transition: .4s;
            &:before {
                position: absolute;
                content: "";
                height: 18px;
                width: 18px;
                left: 2px;
                bottom: 2px;
                background-color: $white;
                border: 1px solid $white;
                transition: .4s;
            }
            &.round {
                border-radius: 16px;
            }
            
            &.round:before {
                border-radius: 50%;
            }
        }
    }
}