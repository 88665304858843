.login {
    background: $dark-blue;
    @include radial-gradient($gradient-light-blue, $gradient-dark-blue);
    .container {
        &.mc-fluid,
        &.mc {
            .main {
                @include devices(mobile) {
                    margin-top: 0;
                }
            }
        }
    }
    .main {
        background: $white;
        margin: 100px 0;
        padding: 0 110px 10px;
        @include devices(mobile) {
            padding: 15px;
        }
        @include box-shadow-4;
        .mod_article {
            &:first-child {
                margin-top: 200px;
                @include devices (tablet) {
                    margin-top: 140px;
                }
                @include devices (mobile) {
                    margin-top: 100px;
                }
            }
        }
        #logo {
            position: absolute;
            top: 0;
            z-index: 2;
            @include devices (tablet) {
                img {
                    max-height: 120px;
                }
            }
            @include devices (mobile) {
                img {
                    max-height: 90px;
                }
            }
        }
        .login-header {
            position: absolute;
            top: 0;
            right: 0;
            img {
                max-width: 1295px;
                @include devices (tablet) {
                    max-width: 665px;
                }
            }
        }
        .select-language {
            position: absolute;
            top: -40px;
            right: 10px;
            @include devices (tablet) {
                top: -47px;
            }
            @include devices (mobile) {
                top: 0;
                z-index: 1;
            }
        }

        .logos {
            margin-top: 60px;
            .logo-list {
                .logo {
                    img {
                        max-width: 130px;
                        height: auto;
                        margin: 5px;
                        @include devices (tablet) {
                            max-width: 80px;
                        }
                    }
                }
            }
        }
    }

    #login-page {
        .fieldset_forgotpassword {
            .captcha_img {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                border: none;
                img {
                    max-height: 38px;
                }
            }
        }
        .custom_content {
            h3 {
                color: $red;
            }
        }
        .btn-primary {
            &:not(.back-button) {
                margin-top: 5px;
                float: right;
            }
        }
    }
}
