.footer {
    .footer-nav {
        min-height: 296px;
        background: $dark-blue;
        @include radial-gradient($gradient-light-blue, $gradient-dark-blue);
        padding-top: 40px;
        .mod_customnav {
            color: $white;
            a {
                color: $white;
                &:hover {
                    text-decoration: underline;
                    color: $white;
                }
            }
            h3 {
                pointer-events: none;
                @include font-size(16px);
            }
        }
    }
    .footer-bottom {
        background: $red;
        color: $white;
        padding: 35px 0;
        a {
            color: $white;
        }
    }

    @include devices (mobile) {
        .footer-nav {
            min-height: unset;
            padding-top: 20px;
            padding-bottom: 10px;
            .mod_customnav {
                h3 {
                    pointer-events: initial;
                    margin-bottom: 10px;
                    &:after {
                        position: absolute;
                        right: 15px;
                        font-family: 'soundunited';
                        content: '\e802';
                        @include font-size(15px);
                    }
                    &.active {
                        padding: 0;
                        margin: 0;
                        &:after {
                            transform: rotate(90deg);
                        }
                    }
                }
                ul {
                    display: none;
                    margin-bottom: 10px;
                }
            }
        }
        .footer-bottom {
            padding: 15px 45px;
            @include font-size(14px);
        }
    }
}