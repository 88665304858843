.my-account {
    .manage-accounts-widget {
        margin-bottom: 30px;
        .account-details {
            background: $light-grey;
            height: 68px;
            border-radius: $radius5;
            padding: 0 15px;
            margin: 0 0 5px 0;
            @include font-size(15px);
            line-height: 22px;
            .actions {
                @include font-size(18px);
            }
            &:last-of-type {
                margin-bottom: 30px;
            }
        }
        
    }
    .formwrapper {
        .help {
            @include font-size(13px);
            font-style: italic;
        }
        .form-group {
            margin-bottom: 15px;
        }
        fieldset {
            margin-bottom: 15px;
            &.fieldset_editpassword {
                legend {
                    @include font-size(26px);
                    font-family: $secundary-font;
                }
            }
        }
        input {
            &::-webkit-input-placeholder { /* WebKit browsers */
                color:    transparent !important;
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color:    transparent !important;
                opacity:  1;
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color:    transparent !important;
               opacity:  1;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10+ */
                color:    transparent !important;
            }
        }
    }
    @include devices(tablet) {
        .manage-accounts-widget {
            .account-details {
                height: unset;
                padding: 15px;
                margin: 0 0 10px 0;
            }
        }
    }
}