.basket {
    .spares {
        .mobile-header {
            display: none;
        }
        .item-product-image {
            width: 10%;
            img {
                padding-right: 10px;
            }
        }
        .item-quantity {
            width: 15%;
        }
        .item-description {
            width: 15%;
        }
        .item-delivery-date {
            width: 10%;
            position: relative;
        }
        .item-stock {
            width: 5%;
        }
        .item-remarks {
            width: 15%;
        }
        .item-price-unit,
        .item-list-price,
        .item-discount,
        .item-net-price {
            width: 10%;
        }
        .item-price-subtotal {
            width: 15%;
        }
        .item-delete {
            width: 4%;
            color: $dark-grey;
            text-align: right;
            padding-bottom: 2px;
            @include font-size(16px);
            &:hover {
                color: $red;
            }
        }
    }
    .sales {
        .mobile-header {
            display: none;
        }
        .item-product-image {
            width: 10%;
            img {
                padding-right: 10px;
            }
        }
        .item-description {
            width: 35%;
        }
        .item-delivery-date {
            width: 10%;
            position: relative;
        }
        .item-stock {
            width: 5%;
        }
        .item-remarks {
            width: 15%;
        }
        .item-price-unit,
        .item-list-price,
        .item-discount,
        .item-net-price {
            width: 10%;
        }
        .item-price-subtotal {
            width: 15%;
        }
        .item-delete {
            width: 10%;
            color: $dark-grey;
            text-align: right;
            padding-right: 15px;
            @include font-size(16px);
            &:hover {
                color: $red;
            }
        }
    }
    

    @include devices(tablet) {
        .spares,
        .sales {
            .mobile-header {
                display: block;
                @include font-size(12px);
                margin-bottom: 2px;
            }
            .add-to-basket {
                .quantity-selector {
                    width: 100%;
                    input {
                        width: 100%;
                    }
                }
            }
            .item-price-unit,
            .item-net-price,
            .item-price-subtotal {
                width: 33%;
            }
            .item-delivery-date {
                width: 100%;
            }
            .item-description {
                width: 90%;
            }
            .item-stock {
                width: 10%;
                padding-right: 15px;
                .stock {
                    margin-left: auto;
                }
            }
            .item-remarks {
                width: 100%;
                margin: 10px 0;
            }
            .item-quantity {
                width: 100%;
                margin-left: 0;
                margin-bottom: 10px;
                margin-top: 10px;
            }
            .item-list-price,
            .item-discount {
                width: 25%;
            }
            .item-delete {
                position: absolute;
                top: -5px;
                right: -15px;
            }
        }
        .spares {
            .item-list-price,
            .item-discount,
            .item-net-price,
            .item-price-subtotal {
                width: 25%;
            }
            .item-delete {
                position: absolute;
                top: 0;
                right: -5px;
                @include font-size(18px);
            }
            .item-stock {
                padding-right: 50px;
                margin-top: -18px;
            }
        }
        .sales {
            .item-price-unit,
            .item-price-subtotal {
                width: 50%;
            }
        }
    }

    @include devices(mobile) {
        .spares,
        .sales {
            .item-description {
                width: 90%;
                margin-bottom: 10px;
            }
            .item-product-image {
                width: 50%;
                margin: auto;
            }
        }
        .spares {
            .item-price-subtotal {
                width: 100%;
                text-align: left;
                margin-top: 10px;
            }
            .item-list-price,
            .item-discount,
            .item-net-price {
                width: 33%;
            }
            .item-delete {
                position: absolute;
                bottom: 0;
                top: unset;
                right: 10px;
            }
            .item-stock {
                margin-top: -30px;
                padding-right: 15px;
            }
        }
        .sales {
            .item-price-unit,
            .item-price-subtotal {
                width: 50%;
            }
        }
    }

    .item-price-subtotal {
        position: relative;
        .loader {
            position: absolute;
            right: 15px;
            top: 0;
            text-align: initial;
        }
    }

    .cart-item {
        .item-list-price,
        .item-price-subtotal {
            @include font-size(18px);
        }
        .form-control {
            min-height: 46px;
            height: 46px;
            border-radius: $radius5;
            border: 1px solid $medium-grey;
        }
    }
    .import-basket-messages {
        margin-bottom: 60px;
        @include font-size(14px);
        .headline {
            color: $red;
            @include font-size(16px);
            font-family: $secundary-font;
            margin-bottom: 5px;
        }
        table {
            box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.20);
            th {
                vertical-align: middle;
                background: $red;
                border-color: $red;
                color: $white;
                font-family: $secundary-font;
                padding: 5px 5px;
            }
            tbody {
                background: $light-grey;
                td {
                    border: 1px solid darken($light-grey, 5%);
                }
            }
        }
    }
}
