#loading_indicator {
    svg {
        width: 80px;
        height: auto;
    }
    .centered {
        width: 80px;
        height: 80px;
    }
    path.ring {
        fill-rule:evenodd;
        clip-rule:evenodd;
        fill:#FFFFFF;
    }
    path.ring1 {
        animation: rotate_counter_clockwise 3.5s linear infinite;
        transform-origin: 49.35% 50.35%;
    }
    path.ring2 {
        animation: rotate_clockwise 2s linear infinite;
        transform-origin: 49.35% 50.35%;
    }
    path.ring3 {
        animation: rotate_clockwise 2s linear infinite;
        transform-origin: 49.35% 50.35%;
    }
    .load-text {
        font-family: $primary-font;
    }
}