.header {
    .dropdown-menu {
        z-index: 4001;
    }
    .align-items-center {
        .col-md-2,
        .col-md-7,
        .col-md-3 {
            order: 0; // CORE OVERRIDE
        }
    }
    .header-top {
        background: $white;
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.3);
        padding: 4px 0 2px;
        color: $top-header-grey;
        @include font-size(13px);
        .select-language {
            .btn.btn-language-selector {
                color: $top-header-grey;
            }
        }
    }

    .header-bottom {
        height: 95px;
        color: $white;
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.4);
        @include gradient(-45deg, $gradient-red-blue);
        .icons-menu {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            .mod_catalog_header_search {
                margin-right: 10px;
            }
        }
    }

    @include devices (tablet) {
        display: block;
        .header-top {
            display: block;
        }
    }
}