// vars

$defaultFontsize: 15px;
$defaultFontsize-xs: 16px;

$radius3:       3px;
$radius5:       5px;
$radius8:       8px;

$timing:        0.3s;
