// gradients 
@mixin gradient($direction, $colors) { 
    background: linear-gradient($direction, $colors);  
    background: -webkit-linear-gradient($direction, $colors);
    background: -moz-linear-gradient($direction, $colors);
    background: -o-linear-gradient($direction, $colors);
    background: -ms-linear-gradient($direction, $colors);
}

@mixin radial-gradient($from, $to) {
	background: -moz-radial-gradient(bottom, circle cover, $from 0%, $to 80%);
	background: -webkit-gradient(radial, bottom center, 0px, bottom bottom, 80%, color-stop(0%, $from), color-stop(80%, $to));
	background: -webkit-radial-gradient(bottom, circle cover, $from 0%, $to 80%);
	background: -o-radial-gradient(bottom, circle cover, $from 0%, $to 80%);
	background: -ms-radial-gradient(bottom, circle cover, $from 0%, $to 80%);
	background: radial-gradient(bottom, circle cover, $from 0%, $to 80%);
	background-color: $dark-blue;
}

// use as @include pseudo;
@mixin pseudo($display: block, $pos: absolute, $content: ''){
    display: $display;
    position: $pos;
	content: $content;
}

// timings 
@mixin transition($values...) {
    transition: $values;
}
    
// font sizes
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

@mixin custom-heading($color: $red, $family: $secundary-font, $size: 20px) {
    color: $color;
    font-family: $secundary-font;
    @include font-size($size);
}

@mixin box-shadow-4 {
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.30);
}

// media queries
@mixin devices ($breakpoint) {
    @if $breakpoint == tablet {    
    @media only screen and (max-width: 820px) {
      @content;
    }
  }
 
  @if $breakpoint == mobile {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }
}