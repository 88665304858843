.select-language {
    .dropdown-menu {
        min-width: 138px;
        @include box-shadow-4;
        border-radius: 0;
        border: none;
        .dropdown-item {
            padding: 2px 0px 2px 32px;
            @include font-size(13px);
            &.active {
                color: $black;
                background-color: transparent;
                &:before {
                    @include pseudo(block, absolute, '\e807');
                    font-family: "soundunited";
                    color: $blue;
                    left: 15px;
                    @include font-size(12px);
                }
            }
            &:hover {
                text-decoration: underline;
                background-color: transparent;
                color: $black;
            }
        }
        &:before,
        &:after {
            content: '';
            position: absolute;
            bottom: 100%;
            left: calc(50% - 6px);
            border: 11px solid transparent;
            border-bottom-color: $shade-grey;
         }
        &:after {
            left: calc(50% - 5px);
            border: 10px solid transparent;
            border-bottom-color: $white;
        }
    }
    .dropdown-menu-center {
        right: auto;
        left: 47% !important;
        top: 56px !important;
        -webkit-transform: translate(-53%, 0) !important;
        -o-transform: translate(-53%, 0) !important;
        transform: translate(-53%, 0) !important;
    }
}