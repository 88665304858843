.stock-sign {
    width: 19px;
    height: 19px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    &.out-of-stock {
        background: $info-red;
    }
    &.in-stock {
        background: $info-green;
    }
    &.warning-stock {
        background: $info-orange;
    }
}