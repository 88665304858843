.mod_catalog_special_list {
    .swiper-container {
        padding-bottom: 40px;
        .swiper-pagination {
            bottom: -5px;
        }
    }

    .special_list_image {
        max-width: 1340px;
    }
   
    .special_list_content {
        color: $white;
        padding: 180px 30px 100px 30px;
        .textual-content {
            margin-bottom: 40px;
            .heading {
                @include font-size(36px);
                font-family: $secundary-font;
                text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
            }
            .subheading {
                @include font-size(24px);
                line-height: 28px;
                width: 60%;
                text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
            }
        }
        .product-card {
            .card-img-holder {
                min-height: 230px;
            }
        }
    }
    .headline {
        margin-top: -50px;
        margin-bottom: 30px;
        color: $white;
        @include font-size(28px);
    }

    @include devices(tablet) {
        @include gradient(-45deg, $gradient-red-blue);
        border-radius: $radius8;
        .special_list_image {
            display: none;
        }
        .special_list_content {
            padding: 30px;
            .textual-content {
                margin: 20px 0 0 0;
                .heading {
                    @include font-size(28px);
                    line-height: 34px;
                }
                .subheading {
                    width: 100%;
                    @include font-size(19px);
                }
            }
        }
    }
}