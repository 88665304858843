.news-detail {
    .mod_newsmenu {
        h1 {
            color: $red;
            @extend .h3;
            margin-bottom: 20px;
        }
        ul {
            padding-left: 25px;
            li {
                a {
                    line-height: 35px;
                    color: $black;
                }
                &::marker {
                    color: $red;
                }
            }
        }
    }

    .news-info-block {
        p {
            background: $light-grey;
            padding: 15px;
            color: #5D5D5D;
        }
        margin-bottom: 30px;
    }

    .mod_newsreader {
        h1 {
            color: $red;
            @extend .h3;
        }
    }
}

.homepage {
    .mod_newslist {
        margin-top: 60px;
        .layout_latest {
            background: $light-grey;
            border-radius: $radius5;
            padding: 15px 5px;
            margin-bottom: 15px;
            .image_container {
                img {
                    padding: 15px;
                    background: $white;
                    border-radius: $radius5;
                }
            }
            h2 {
                a {
                    color: $news-grey;
                }
            }
            .teaser {
                color: $news-grey;
                margin-bottom: 20px;
            }
            .more {
                a {
                    @extend .btn;
                    @extend .btn-blue-outline;
                    transition: .2s ease-in-out;
                    &:hover {
                        text-decoration: none;
                    }
                    .invisible {
                        display: none;
                    }
                }
            }
        }
    }
}