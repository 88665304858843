body {
    background: $white;
    font-family: $primary-font;
    @include font-size($defaultFontsize);
    @include devices (tablet) {
        @include font-size($defaultFontsize-xs); 
        margin-top: 0 !important; // NEEDED TO OVERRIDE CRAPPY CORE CODE
    }
    .container {
        &.mc-fluid,
        &.mc {
            flex: 1 1 auto;
            .main {
                margin-top: 50px;
                margin-bottom: 80px;
                @include devices (mobile) {
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
            }
            .left {
                height: 0%;
                margin-top: 58px;
            }
        }
    }
}

// IE 10 / 11 fixes
.ie10windows,
.mozilla5windows {
    * {
        min-height: 0.01px;
    }
    .container {
        &.mc {
            display: block;
            flex: 0 1 auto;
            min-height: calc(100vh - 96px); // footer height
        }
    }

}

.no-break {
    white-space: nowrap;
}

.flex-break {
    flex-basis: 100%;
    height: 0;
    display: none;
    @include devices(mobile) {
        display: block;   
    }
}