// global styling
.swiper-container {
    .swiper-button-next,
    .swiper-button-prev {
        border-radius: 0;
        @include transition(opacity $timing);
        background: none;
        opacity: 1;
        width: auto;
        height: auto;
        margin-top: 0;
        top: calc(50% - 22px);
        &:after {
            content: '\e802';
            font-family: 'soundunited';
            @include font-size(32px);
            color: $white;
            margin: 7px 0 0 4px;
        }
    }
    .swiper-button-prev {
        left: 10px;
        transform: rotate(180deg);
        &:hover {
            left: 10px;
        }
    } 
    .swiper-button-next {
        right: 10px;
        &:hover {
            right: 10px;
        }
    }
    .swiper-pagination {
        .swiper-pagination-bullet {
            border: none;
            background: $white;
            opacity: .4;
            width: 12px;
            height: 12px;
        }
        .swiper-pagination-bullet-active {
            background: $white;
            border: none;
            opacity: 1;
        }
    }
}

// image carrousel
.mod_image_carrousel {
    .swiper-container {
        border-radius: $radius8;
    }
}

// PDP
.item-detail {
    .gallery-pdp {
        height: 70%;
        margin-bottom: 30px;
        &.swiper-container {
            .swiper-button-next,
            .swiper-button-prev {
                &:after {
                    @include transition(color $timing);
                    color: $dark-grey;
                    @include font-size(44px);
                }
                &:hover {
                    &:after {
                        color: $blue;
                    }
                }
            }
        }
        .swiper-slide {
            img {
                width: auto;
                max-height: 100%;
            }
        }
        .swiper-button-prev {
            left: 10px;
        }
        .swiper-button-next {
            right: 0;
            &:hover {
                right: 0;
            }
        }
        .swiper-button-disabled {
            opacity: 0.2;
        }
    }
    .gallery-thumbs {
        height: 20%;
        .swiper-slide {
            border: none;
            border-radius: $radius5;
            background-size: contain;
            background-repeat: no-repeat;
        }
        .swiper-slide-active {
            border: 1px solid $medium-grey;
        }
    }
}

@include devices(tablet) {
    .item-detail {
        .gallery-pdp {
            height: 45%;
        }
        .gallery-thumbs {
            height: 15%;
            .swiper-slide {
                width: 20%;
            }
        }
    }
}

@include devices(mobile) {
    .item-detail {
        .gallery-pdp {
            height: 80%;
        }
        .gallery-thumbs {
            height: 20%;
            .swiper-slide {
                width: 20%;
            }
        }
    }
}