.mini-basket {
    .dropdown-menu {
        @include box-shadow-4;
        border-radius: 0;
        border: none;
        .mini-basket-items {
            .loader {
                position: absolute;
                right: 15px;
            }
            .list-group-flush .list-group-item {
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .item-price {
                width: 40%;
            }
        }
    }
    .dropdown-menu-right {
        left: 15px !important;
        top: 15px !important;
        @include devices(mobile) {
            left: 9px !important;
        }
        max-height: initial !important;
    }
    .btn-mini-basket {
        .icon-cart {
            color: $white;
            font-family: "soundunited";
            @include font-size(16px);
            &:before {
                content: "\e80b";
                left: 5px;
                position: absolute;
                top: 9px;
            }
        }
    }
}

.header {
    .basket-counter {
        background: $white;
        color: $black;
        top: -9px;
        right: -9px;
        line-height: 20px;
    }
}