.add-to-basket {
    position: relative;
    input[type=number] {
        -moz-appearance: textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    .quantity-message {
        background: $red;
        border: none;
        color: $white;
        padding: 7px 28px 7px 10px;
        width: 100%;
        position: absolute;
        bottom: 40px;
        border-radius: $radius5;
        @include font-size(14px);
        .close {
            top: 5px;
            right: 7px;
            padding: 2px 3px;
            text-shadow: none;
            opacity: .7;
            color: $white;
            @include font-size(21px);
            &:hover {
                opacity: 1;
            }
        }
    }
    .quantity-selector {
        width: 130px;
        height: 36px;
        background: $blue;
        border-radius: 18px;
        input {
            width: 50px;
            height: 36px;
            color: $white;
            font-family: $secundary-font;
            background: $blue;
            border: none;
            @include font-size(18px);
        }
        .btn-decrease,
        .btn-increase {
            font-family: $secundary-font;
            @include font-size(20px);
            width: 40px;
            height: 36px;
            padding: 4px 0;
        }
    }
}

//LISTER
.lister,
.swiper-container {
    .add-to-basket {
        .quantity-selector {
            width: 100%;
            height: 46px;
            border-radius: 23px;
            background: $white;
            border: 1px solid $blue;
            flex-wrap: wrap;
            justify-content: space-between;
            .btn-spinner {
                width: auto !important;
                padding: 6px 20px !important;
                color: $blue;
            }
            input {
                background: $white;
                color: $blue;
                height: 100%;
                @include font-size(20px);
            }
        }
    }
}