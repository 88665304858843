.basket {
    .mod_ordering_basket {
        .messages {
            .alert {
                &.alert-error {
                    background-color: $info-red;
                    color: white;
                }
            }
        }
        .heading {
            h1 {
                @extend .h2;
                margin-bottom: 15px;
                &:before {
                    content: "";
                    display: none;
                }
            }
        }
        .addresses {
            .box {
                background: $light-grey;
                padding: 15px;
                border-radius: $radius5;
            }
        }
    }
    .cart-list {
        margin-bottom: 30px;
        .item-row {
            position: relative;
        }
        .cart-header {
            font-family: $secundary-font;
            border-bottom: none;
            @include font-size(14px);
            padding: 0 15px;
            > div {
                margin-bottom: 25px;
            }
        }
        .cart-item {
            border: 1px solid $light-grey;
            border-radius: $radius5;
            margin-bottom: 15px;
            padding: 15px;
        }
    }

    .cart-summary {
        .po-number {
            i {
                position: absolute;
                right: -10px;
                top: 0;
                width: 18px;
                height: 18px;
                color: $white;
                background: $red;
                font-family: $secundary-font;
                border-radius: 50%;
                padding-left: 5px;
                font-style: normal;
            }
            span {
                position: absolute;
                right: -188px;
                top: -23px;
                z-index: 10;
                @include font-size(13px);
            }
        }
        .cart-totals {
            background: $light-grey;
            padding: 15px;
            border-radius: $radius5;
            small {
                color: $dark-grey;
                @include font-size(11px);
            }
        }
    }

    .addresses {
        margin-top: 30px;
        margin-bottom: 60px;
        h3 {
            color: $red;
        }
    }
    
    @include devices(tablet) {
        .cart-summary {
            .po-number {
                span {
                    position: absolute;
                    right: -30px;
                    top: -25px;
                    background: transparent;
                    @include font-size(13px);
                }
            }
        }
    }

    @include devices(mobile) {
        .addresses {
            margin-bottom: 30px;
        }
        .cart-summary {
            .po-number {
                i {
                    position: absolute;
                    right: 7px;
                    top: -10px;
                }
                span {
                    position: absolute;
                    right: 7px;
                    top: -29px;
                    background: transparent;
                    @include font-size(13px);
                }
            }
        }
    }
}
