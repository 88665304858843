.ce_downloads {
    margin-top: 50px;
    h1 {
        @extend .h3;
    }
    ol {
        li {
            margin: 5px 0;
            .icon {
                @include font-size(18px);
            }
        }
    }
    
}