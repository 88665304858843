.mod_catalog_filter {
    margin-top: 5px;
    > h4 {
        display: none;
    }
    .card {
        background-color: $light-grey;
        .card-header {
            color: $red;
            @include font-size(18px);
            padding: 0 0 7px;
            i {
                display: none;
                visibility: hidden;
                opacity: 0;
            }
        }
        .filter_option {
            background-color: $light-grey;
            padding: 0;
            margin-top: 6px;
            .form-check {
                margin-right: 0;
            }
            .form-label {
                color: $black;
                @include font-size(14px);
                .count {
                    color: $dark-grey;
                    @include font-size(11px);
                }
            }
        }
        .showAll {
            margin-top: 5px;
            a {
                color: $red;
                @include font-size(13px);
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .btn-clear-all {
        margin-bottom: 20px;
    }

    @include devices(tablet) {
        .btn-clear-all {
            width: 100%;
        }
        .card {
            padding: 15px;
        }
    }
}