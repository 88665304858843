// primary colors
$red:               #BA0C2F;
$blue:              #00A4E4;
$dark-blue:         #001234;

// informative colors
$info-red:          #BA0C2F;
$info-green:        #07A936;
$info-orange:       #FFAF00;

// radial gradient
$gradient-light-blue:    rgba(48,107,173,.8);
$gradient-dark-blue:     rgba(9,36,77,0);

// normal gradient
$gradient-red-blue:    $red 0%, $dark-blue 45%;

// white to black
$white:             #FFF;
$black:             #231F20;
$light-grey:        #F5F5F5;
$medium-grey:       #C7C8CA;
$dark-grey:         #939598;
$top-header-grey:   #464547;
$shade-grey:        #eee;
$news-grey:         #3A3335;
$listertile-grey:   #e5e5e5;

// css color classes
.c-info-red {
    color: $info-red;
}

.c-info-green {
    color: $info-green;
}

.c-info-orange {
    color: $info-orange;
}

.c-color-grey {
    color: $dark-grey;   
    &:hover {
        color: $black;
    }
}

.greyedOut {
    color: $medium-grey !important;
}

.no-results-found {
    color: $info-red;
}
