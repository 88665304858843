//GLOBAL PRODUCT TILE
.product-card {
    background: $white;
    border-radius: $radius5;
    border: 1px solid $listertile-grey;
    color: $black;
    @include transition(box-shadow $timing);
    &:hover {
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.15);
    }
    .card-img-holder {
        min-height: 200px;
    }
    .card-img-top {
        max-height: 200px;
        width: auto;
    }
    .card-body {
        padding: 15px;
        .card-title {
            font-family: $secundary-font;
            @include font-size(18px);
            margin-bottom: 5px;
            a {
                color: $black;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .item-title {
            line-height: 20px;
            min-height: 42px;
            a {
                color: $black;
                @include font-size(14px);
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .price {
            margin-bottom: 10px;
            margin-top: 15px;
            min-height: 25px;
            .SalePrice {
                font-family: $secundary-font;
                @include font-size(22px);
            }
            .price-vat {
                color: $dark-grey;
                @include font-size(11px);
                font-family: $primary-font;
            }
        }
        .add-to-basket {
            width: 100%;
            .button-wrapper {
                width: 100%;
            }
            .btn {
                width: 100%;
                padding: 8px 20px;
                &.loading {
                    width: 100% !important;
                }
            }
        }
        .stock {
            min-height: 27px;
            margin-top: 10px;
            @include font-size(13px);
            .icon {
                @include font-size(14px);
            }
        }
    }

    // LOADER
    .stock,
    .price {
        &.loading:not(.no-price) {
            width: 100%;
            @extend .skeletonLoader;
        }
    }

    .price {
        margin: 0 0 10px;
        line-height: 1.5rem;
        &.loading {
            min-height: 27px;
        }
    }

    .stock {
        &.loading {
            min-height: 27px;
        }
    }
}

//SWIPER PRODUCT TILE
.swiper-container {
    .product-card {
        border: none;
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
        .card-img-top {
            max-height: 230px;
            width: auto;
        }
    }
}