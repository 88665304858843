.spares-quicksearch-widget {
    margin-top: 40px;
    .form-control {
        min-height: 46px;
        height: 46px;
        border-radius: $radius5;
        border: 1px solid $dark-grey;
    }
    .search-bar {
        margin-bottom: 60px;
        .quicksearch-btn {
            position: absolute;
            top: 5px;
            right: 25px;
            @include font-size(19px);
            color: $dark-grey;
        }
    }
    .bottom {
        margin-top: 25px;
    }
    .heading {
        padding: 0 15px;
        @include font-size(14px);
        font-family: $secundary-font;
        line-height: 16px;
        margin-bottom: 15px;
    }
    .quicksearch-results {
        .alt-header {
            font-family: $secundary-font;
            @include font-size(14px);
            margin-left: 20px;
        }
        .result {
            background: $light-grey;
            height: 68px;
            border-radius: $radius5;
            padding: 0 15px;
            margin: 0 0 5px 0;
            @include font-size(14px);
            line-height: 20px;
            align-items: center;
            border: 1px solid transparent;
            .orderable {
                @include font-size(20px);
                line-height: 15px;
            }
            &.alternative {
                background: transparent;
                align-items: stretch;
                padding-right: 0;
                padding-left: 0;
                flex-wrap: nowrap;
                margin: 0 15px 5px;
                > div {
                    background: darken($light-grey, 15%);
                    display: flex;
                    align-items: center;
                    padding-right: 0;
                    justify-content: flex-start;
                    &.quantity {
                        justify-content: center;
                    }
                    &:first-child {
                        padding-left: 10px;
                        border-top-left-radius: $radius5;
                        border-bottom-left-radius: $radius5;
                    }
                    &:last-child {
                        border-top-right-radius: $radius5;
                        border-bottom-right-radius: $radius5;
                        &.alternative {
                            justify-content: space-between;
                            .row {
                                flex: 1 1 auto;
                            }
                        }
                    }
                }
            }
        }
        .alternative {
            background: $dark-grey;
        }
    }
    @include devices(tablet) {
        .search-bar {
            margin-bottom: 20px;
            .btn {
                width: 100%;
            }
        }
        .bottom {
            margin-top: 10px;
            .btn {
                width: 100%;
            }
        }
        .heading {
            display: none;
        }
        .quicksearch-results {
            .result {
                height: auto;
                padding: 15px;
                margin: 0 0 10px 0;
                .quantity {
                    .button-wrapper {
                        width: 100%;
                    }
                    .btn {
                        width: 100%;
                    }
                }
            }
        }
    }
    @include devices(mobile) {
        .quicksearch-results {
            .quantity {
                order: 1;
            }
            .alt-header {
                margin-left: 15px;
            }
            .result {
                &.alternative {
                    flex-wrap: wrap;
                    background: darken($light-grey, 15%);
                    margin: 0 10px 10px;
                    > div {
                        &.quantity {
                            padding: 0px 10px 15px;
                            .add-to-basket {
                                width: 100%;
                            }
                        }
                        &:last-child {
                            padding-right: 15px;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }
}
