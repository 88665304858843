.accordion {
    margin-top: 60px;
    .card {
        margin-bottom: 15px;
        border: none;
        border-radius: 0;
        .card-header {
            border-radius: $radius8;
            background: $dark-blue;
            padding: 18px 15px;
            a {
                color: $white;
                &:hover {
                    text-decoration: none;
                }
                h5 {
                    @include font-size(21px);
                    font-family: $secundary-font;
                    .icon {
                        @include transition(transform $timing);
                    }
                }
                &.collapsed {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .card-body {
            padding: 30px 15px;
        }
    }
    @include devices(tablet) {
        margin-top: 30px;
    }
}