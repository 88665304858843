.lister {
    .mod_catalog_list {
        .catalog_list_options {
            @include devices(tablet) {
                .row {
                    width: 100%;
                }
            }
            @include devices(mobile) {
                .row {
                    width: auto;
                }
            }
            &.top {
                background: $dark-blue;
                color: $white;
                border-radius: $radius3;
                @include devices(tablet) {
                    border-radius: $radius8;
                }
                margin-bottom: 15px;
                .catalog_sorting_options {
                    margin: 10px 0;
                }
                .form-group {
                    margin-bottom: 0;
                }
                select.form-control {
                    background: $white;
                    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M4.93457944,7.8 L0.274143302,3.13956386 C-0.0479750779,2.81744548 -0.0479750779,2.29657321 0.274143302,1.97788162 L1.04859813,1.20342679 C1.37071651,0.881308411 1.89158879,0.881308411 2.21028037,1.20342679 L5.51370717,4.50685358 L8.81713396,1.20342679 C9.13925234,0.881308411 9.66012461,0.881308411 9.9788162,1.20342679 L10.753271,1.97788162 C11.0753894,2.3 11.0753894,2.82087227 10.753271,3.13956386 L6.09283489,7.8 C5.77757009,8.12211838 5.25669782,8.12211838 4.93457944,7.8 L4.93457944,7.8 Z' id='path-1'></path></svg>");
                    background-repeat: no-repeat;
                    background-position-x: 100%;
                    background-position-y: 12px;
                    border: 1px solid #dfdfdf;
                    border-radius: $radius3;
                    margin: 0 10px;
                    transform: scale(1.1);
                    @include font-size(12px);
                    padding: 2px 50px 0 8px;
                    display: inline-block;
                    width: auto;
                }
            }
        }
    }

    .container.mc {
        .left {
            .show-results {
                display: none;
            }
            @include devices(tablet) {
                padding: 15px;
                position: fixed;
                top: 0;
                bottom: 0;
                z-index: 3001;
                overflow: auto;
                background: $white;
                height: auto;
                margin-top: 0;
                left: -100%;
                @include transition(left $timing);
                &.active {
                    left: 0;
                }
                .show-results {
                    display: block;
                }
            }
        }
    }
}