.header {
    .navbar {
        @include devices(tablet) {
            height: auto;
            .navbar-nav {
                flex-direction: column;
            }
        }
        background: none;
        .level1 {
            .nav-link {
                padding: 13px 12px;
                letter-spacing: -0.3px;
                &:focus {
                    outline: none;
                }
            }
            &.dropdown {
                position: relative;
                &:hover {
                    > a {
                        background: none;
                        color: $white;
                        text-decoration: underline;
                        &:before {
                            display: none;
                        }
                    }
                }
                > a:after {
                    border-bottom: 0;
                    border-left: 0.3em solid transparent;
                    border-right: 0.3em solid transparent;
                    border-top: 0.3em solid;
                    content: "";
                    display: inline-block;
                    margin-left: 0.255em;
                    vertical-align: 0.255em;
                    position: relative;
                    top: 0;
                    left: 5px;
                    float: none;
                }
            }
        }
        .nav-link {
            &.dropdown-toggle {
                &:after {
                    display: none;
                }
            }
        }
        .dropdown-menu {
            border-radius: $radius5;
            top: 49px;
            .nav-item {
                @include font-size(15px);
                color: $black;
                font-weight: 500;
                &:before {
                    @include pseudo(block, absolute, '\e801');
                    font-family: "soundunited";
                    color: $blue;
                    margin-left: -16px;
                    margin-top: 1px;
                    @include font-size(13px);
                    transform: rotate(180deg);
                }
            }
        }
    }
}

#catalog_navigation {
    display: inline-block;
    @include devices(mobile) {
        display: block;
    }
}
