.form-control {
    border-radius: 8px !important; //override core
    color: $black !important;
    border: 1px solid $medium-grey;
    &::placeholder {
        color: $black;
    }
    &[readonly] {
        background: $white;
    }
}

input[type=date] {
    &:before {
        content: attr(placeholder) !important;
    }
}

.input_date.flatpickr-input.flatpickr-mobile {
    appearance: none;
    -webkit-appearance: none;
}

.date_icon {
    position: absolute;
    top: 9px;
    right: 6px;
    color: $dark-grey;
    @include devices (tablet) {
        top: 32px;
    }
}

.input_date {
    @include font-size($defaultFontsize);
    @include devices (tablet) {
        @include font-size($defaultFontsize-xs); 
    }
    text-align: left;
}
.date-header,
.remarks-header {
    display: none;
    @include devices (tablet) {
        display: block;
    }
}

// bootstrap overwrites
.formwrapper {
    input:not([type=radio]):not([type=checkbox]):not([class*=search]):not([type=range]),
    select,
    textarea {
        box-shadow: none;
        background: $white;
        font-family: $primary-font !important;
        @include font-size($defaultFontsize);
        @include devices (tablet) {
            @include font-size($defaultFontsize-xs); 
        }
        border: 1px solid $medium-grey;
        min-height: 40px;
        border-radius: 20px;
        padding: 5px 15px;
        color: $black;
        margin-bottom: 15px;
        &::placeholder {
            color: $dark-grey;
        }
        &:active,
        &:focus {
            outline: none;
            border-color: $medium-grey;
            color: $black;
        }
        &.error {
            border: 1px solid $red;
        }
    }
    select {
        color: $dark-grey;
    }
    .mandatory-indicatory {
        color: $red;
    }
    label {
        font-size: 100%;
        margin: 0 0 2px;
        color: $black;
        &.error {
            .mandatory-indicatory {
                color: $red;
            }
        }
    }
    .js-toggle-password {
        top: 32px;
        color: $medium-grey;
        &:before {
            font-family: "core-frontend" !important;
        }
    }
    .fieldset_login,
    .fieldset_forgotpassword,
    .fieldset_member_customers,
    .fieldset_personal,
    .fieldset_account {
        legend {
            @include custom-heading($red, $secundary-font, 20px);
        }
    }
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none;
}

//CUSTOM RADIO BUTTON
.radio {
    position: relative;
    display: flex;
    align-items: center;
    margin:  0 5px 0;
    max-width: 30px;
    .radio-input {
        margin: 0 5px 0 0;
        border: 1px solid $medium-grey;
        background: $white;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        -webkit-appearance: none;
        &.active {
            border: 1px solid $blue;
        }
        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            margin: 3px 3px 3px;
            border-radius: 6px;
        }
        &:checked {
            border: 1px solid $blue;
        }
        &:checked:before {
            background: $blue;
        }
        // fallback for IE11
        &:checked::-ms-check {
            border: 1px solid $blue;
            color:$blue;
            opacity: 1;
        }
    }
}

//CUSTOM CHECKBOX
.checkbox-container {
    display: block;
    position: relative;
    padding: 0 0 0 25px;
    cursor: pointer;
    user-select: none;
    &.bigger {
        padding: 10px 0 0 45px;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked {
            ~ .checkmark {
                background: $white;
                &:after {
                    display: block;
                }
            }
        }
    }
    .checkmark {
        border-radius: $radius3;
        border: 1px solid $medium-grey;
        position: absolute;
        top: -4px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $white;
        &:after {
            top: -1px;
            left: 2px;
            @include font-size(14px);
            font-family: 'soundunited';
            content: "\e807";
            color: $blue;
            position: absolute;
            display: none;
        }
        &.bigger {
            height: 38px;
            width: 38px;
            border-radius: $radius8;
            color: $black;
            top: 6px;
            &:after {
                @include font-size(25px);
                top: 0;
                left: 5px;
            }
        }
    }
}