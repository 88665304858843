.sales-rep-selection-widget legend {
    @include custom-heading($red, $secundary-font, 20px);
}

.customer-selection-widget, sales-rep-selection-widget {
    .letter-select {
        .letter {
            margin: 0 5px;
            @include devices (tablet) {
                margin: 5px 8px;
                font-size: 18px;
            }
            a {
                color: $black;
                &:hover {
                    color: $red;
                }
            }
            &.disabled {
                color: $medium-grey;
            }
        }
    }
    .heading {
        .reset-letters {
            @include transition(transform $timing, padding $timing);
            &:hover {
                transform: rotate(50deg);
                padding-left: 2px;
                text-decoration: none;
            }
        }
    }
}

.customer-selection, .sales-rep-selection {
    // CUSTOM SCROLLBAR FOR CUSTOMER SELECTION ONLY
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    margin-top: 15px;
    max-height: 500px;
    overflow-y: scroll;
    .customer, .sales-rep {
        background: $white;
        height: 50px;
        border-radius: 25px;
        padding: 0 15px;
        margin: 0 0 5px 0;
        @include font-size(14px);
        line-height: 20px;
        align-items: center;
        border: 1px solid transparent;
        @include devices(tablet) {
            align-items: flex-start;
            height: unset;
            padding: 10px 15px;
            line-height: 24px;
        }
        &:nth-of-type(odd) {
            background: $light-grey;
        }
        &:last-child {
            margin: 0 0 30px 0;
        }
        &.active {
            border: 1px solid $blue;
        }
        .checkbox {
            max-width: 25px;
        }
        .radio {
            @include devices(tablet) {
                max-width: unset;
                margin: 0;
            }
        }
    }
    .heading {
        margin: 0;
        padding: 0 15px;
        font-family: $secundary-font;
        @include devices(mobile) {
            display: none;
        }
    }
}
