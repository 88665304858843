.btn:not(.btn-text),
.btn {
    font-family: 'Gotham-Medium';
    font-weight: 500;
    @include font-size(20px);
    box-shadow: none;
    border-radius: 23px;
    height: 46px;
    min-height: auto;
    min-width: auto;
    padding: 6px 30px;
    color: $white;
    @include devices (tablet) {
        @include font-size(18px);
        line-height: 32px;
    }
    &.btn-primary {
        background: $red;
        border: 2px solid $red;
        @include devices (tablet) {
            padding: 6px 20px;
        }
        &:hover {
            background: $blue;
            border: 2px solid $blue;
            color: $white;
        }
    }
    &.btn-secondary {
        background: $white;
        border: 1px solid $red;
        padding: 8px 30px;
        @include devices (tablet) {
            padding: 8px 20px;
        }
        color: $red;
        &:hover {
            border: 1px solid $red;
            background: $red;
            color: $white;
        }
    }

    &.btn-blue-outline {
        background: $white;
        border: 1px solid $blue;
        color: $blue;
        padding: 8px 30px;
        &:hover {
            background: $blue;
            color: $white;
        }
    }

    &.is-disabled {
        background: $medium-grey;
        border: 1px solid $medium-grey;
        color: $dark-grey;
        padding: 8px 30px;
        &:hover {
            background: $dark-grey;
            border: 1px solid $dark-grey;
            color: darken($dark-grey, 25%);
        }
    }

    &.btn-add-to-cart {
        background: $white;
        border: 1px solid $red;
        color: $red;
        padding: 8px 42px;
        &:hover {
            background: $blue;
            border: 1px solid $blue;
        }
    }

    &.btn-personal-menu {
        color: $top-header-grey;
        padding: 0;
        height: auto;
    }

    &.btn-language-selector {
        color: $white;
        padding: 0;
        height: auto;
        @include devices (tablet) {
            @include font-size(23px);
        }
        @include devices (mobile) {
            @include font-size(26px);
            color: $black;
        }
    }

    &.btn-header-search {
        padding: 0;
        margin: 0;
        height: 36px;
    }

    &.btn-search {
        padding: 0 10px;
        @include font-size(13px);
        height: 40px;
    }
    
    &.btn-clear-all {
        padding-left: 50px;
        &:before {
            @include transition(color $timing);
            @include pseudo(block, absolute, '\e811');
            font-family: "soundunited";
            color: $blue;
            margin-left: -25px;
            margin-top: 2px;
            @include devices (tablet) {
                margin-top: 0;
            }
            @include font-size(18px);
        }
        &:hover {
            &:before {
                color: $white;
            }
        }
    }

    &.btn-mini-basket {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 0px 2px;
        margin: 0;
        background: $blue;
        &:after {
            content: "";
            display: none;
        }
        &:hover {
            background: lighten($blue, 10%);
        }
    }

    &.btn-small {
        height: 36px;
        width: 100%;
        border-radius: 18px;
        padding: 6px 40px;
        @include font-size(18px);
        @include devices (tablet) {
            @include font-size(16px);
            padding: 2px 40px;
        }
    }

    &.disabled {
        pointer-events: none;
    }

    &.back-button {
        background: transparent;
        border-color: transparent;
        color: $black;
        @include font-size(15px);
        font-family: $primary-font;
        @include devices (tablet) {
            padding: 6px 30px;
        }
        &:hover,
        &:active {
            background: transparent !important;
            background-color: transparent !important;
            border-color: transparent !important;
            color: $black !important;
            text-decoration: underline;
        }
        &:before {
            @include pseudo(block, absolute, '<');
            left: 18px;
        }
    }

    // LOADER
    &.btn-loading:not(.btn-no-nla):not(.btn-no-price):not(.btn-no-quantity):not(.btn-not-indivisible) {
        &.loading {
            @extend .skeletonLoader;
            border-radius: $radius5;
            color: transparent;
            cursor: auto;
            min-height: 30px;
        }
    }

    &.btn-no-preloader {
        display: none;
    }

    &.btn-no-nla,
    &.btn-no-price,
    &.btn-no-quantity,
    &.btn-not-indivisible {
        @extend .btn-disabled;
        border-radius: 23px !important;
        padding: 8px 40px;
    }

    &.btn-disabled {
        background: $light-grey;
        color: $medium-grey !important;
        border: 1px solid $medium-grey;
        cursor: no-drop;
    }
}

.spares-quicksearch-widget {
    .btn-loading {
        &.loading {
            border-radius: 30px !important;
            animation: none !important;
            background: $light-grey !important;
            color: $medium-grey !important;
            border: 1px solid $medium-grey !important;
        }
    }
}