.search-box {
    //custom delete input icon
    span.delete-icon {
        position: relative;
        display: inline-flex;
        align-items: center;
        span {
            position: absolute;
            display: block;
            right: 6px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            color: $light-grey;
            background-color: $medium-grey;
            @include font-size(10px);
            font-weight: 700;
            text-align: center;
            line-height: 1em;
            cursor: pointer;
            padding-top: 3px;
            &:hover {
                background-color: $blue;
            }
        }
        input {
            padding-right: 24px;
            box-sizing: border-box;
        }
    }
    .dropdown-menu {
        padding: 20px 15px 20px 0;
        @include box-shadow-4;
        border-radius: 0;
        border: none;
        left: 6px !important; // OVERRULE BOOTSTRAP ALIGNMENT
        top: 15px !important; // OVERRULE BOOTSTRAP ALIGNMENT
        &:before,
        &:after {
            content: '';
            position: absolute;
            bottom: 100%;
            right: 12px;
            border: 11px solid transparent;
            border-bottom-color: $shade-grey;
         }
        &:after {
            right: 12px;
            border: 10px solid transparent;
            border-bottom-color: $white;
        }
    }
    input {
        height: 40px;
        border-radius: 20px;
        border: 1px solid $medium-grey;
    }
    @include devices(mobile) {
        .dropdown-menu {
            padding: 15px;
            min-width: 250px;
        }
    }
}