@font-face {
    font-family: 'Gotham-Book';
    src: url('../fonts/Gotham-Book.ttf') format('truetype'),
         url('../fonts/Gotham-Book.woff') format('woff'),
         url('../fonts/Gotham-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'Gotham-Medium';
    src: url('../fonts/Gotham-Medium.ttf') format('truetype'),
         url('../fonts/Gotham-Medium.woff') format('woff'),
         url('../fonts/Gotham-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}


$primary-font: 'Gotham-Book';
$secundary-font: 'Gotham-Medium';


@font-face {
    font-family: 'soundunited';
    src: url('../fonts/soundunited.eot?46051165');
    src: url('../fonts/soundunited.eot?46051165#iefix') format('embedded-opentype'),
         url('../fonts/soundunited.woff2?46051165') format('woff2'),
         url('../fonts/soundunited.woff?46051165') format('woff'),
         url('../fonts/soundunited.ttf?46051165') format('truetype'),
         url('../fonts/soundunited.svg?46051165#soundunited') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
   
   [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "soundunited" !important;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
   
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
   
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
   
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
   
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
   
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }

  .icon-angle-down-solid:before { content: '\e800'; } /* '' */
  .icon-angle-left-solid:before { content: '\e801'; } /* '' */
  .icon-angle-right-solid:before { content: '\e802'; } /* '' */
  .icon-globe-americas-solid:before { content: '\e803'; } /* '' */
  .icon-truck-solid:before { content: '\e804'; } /* '' */
  .icon-user-solid:before { content: '\e805'; } /* '' */
  .icon-chart-pie-solid:before { content: '\e806'; } /* '' */
  .icon-check-solid:before { content: '\e807'; } /* '' */
  .icon-envelope-solid:before { content: '\e808'; } /* '' */
  .icon-redo-alt-solid:before { content: '\e809'; } /* '' */
  .icon-search-solid:before { content: '\e80a'; } /* '' */
  .icon-shopping-cart-solid:before { content: '\e80b'; } /* '' */
  .icon-angle-double-right-solid:before { content: '\e80c'; } /* '' */
  .icon-calendar-alt-regular:before { content: '\e80d'; } /* '' */
  .icon-edit-regular:before { content: '\e80e'; } /* '' */
  .icon-spinner-solid:before { content: '\e80f'; } /* '' */
  .icon-trash-alt-regular:before { content: '\e810'; } /* '' */
  .icon-times-solid:before { content: '\e811'; } /* '' */
  .icon-download-cloud:before { content: '\f0ed'; } /* '' */
  .icon-font:before { content: '\f0f6'; } /* '' */
  .icon-file-pdf-o:before { content: '\f1c1'; } /* '' */
  .icon-file-word-o:before { content: '\f1c2'; } /* '' */
  .icon-file-excel-o:before { content: '\f1c3'; } /* '' */
  .icon-file-powerpoint-o:before { content: '\f1c4'; } /* '' */
  .icon-file-image-o:before { content: '\f1c5'; } /* '' */
  .icon-code:before { content: '\f1c9'; } /* '' */


  // REVERSE THE CORE EYES
  .icon-eye-close:before {
    content: "<";
  }
  .icon-eye-open:before {
    content: ";";
  }
  


.bold {
    font-family: $secundary-font;
    font-weight: 500 !important; // CORE
}

a {
    color: $red;
    &.red-link {
        color: $red;
        @include font-size($defaultFontsize);
        @include devices (tablet) {
            @include font-size($defaultFontsize-xs); 
        }
    }
}

.back {
    a {
        margin-left: 15px;
        color: $black;
        position: relative;
        &:before {
            @include pseudo(block, absolute, '\e80c');
            font-family: "soundunited";
            color: $blue;
            left: -15px;
            top: -2px;
            @include font-size(12px);
            transform: scaleX(-1);
        }
    }
}

h1,
.h1 {
    @include font-size(32px);
    line-height: 38px;
    font-family: $secundary-font;
}

h2,
.h2 {
    @include font-size(26px);
    line-height: 32px;
    font-family: $secundary-font;
}

h3,
.h3 {
    @include font-size(21px);
    line-height: 27px;
    font-family: $secundary-font;
}

h4,
.h4 {
    @include font-size(18px);
    line-height: 24px;
    font-family: $secundary-font;
}

h5,
.h5 {
    @include font-size(14px);
    line-height: 20px;
    font-family: $secundary-font;
}

h6,
.h6 {
    @include font-size(12px);
    line-height: 18px;
    font-family: $secundary-font;
}






