.modal-full-width {
    width: 95%;
    max-width: 95%;
}

.modal {
    .modal-close {
        position: absolute;
        right: -17px;
        top: -17px;
        width: 35px;
        height: 35px;
        background: $news-grey;
        border: 2px solid $white;
        border-radius: 50%;
        padding: 3px 7px;
        .close {
            font-size: 27px;
            color: $white;
            opacity: 1;
        }
    }
    .modal-body {
        padding: 0;
    }
    .striped {
        thead {
            background: $news-grey;
            color: $white;
            th {
                border: none;
            }
        }
        tbody {
            > tr {
                background: $white;
                border-radius: $radius5;
                &:nth-child(odd) {
                    background: $light-grey;
                }
            }
            td {
                border: none;
                padding: 5px 10px;
            }
        }
        table {
            margin: 0;
            td {
                padding: 0;
                word-wrap: break-word;
                width: 50%;
            }
        }
    }
}

.open-order-summary {
    table {
        tr {
            background: $white;
            border-radius: $radius5;
            &:nth-child(odd) {
                background: $light-grey;
            }
        }
        td {
            border: none;
            padding: 10px 10px;
        }
    }
}