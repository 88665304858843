.pdp {
    .item-detail {
        .pdp-price {
            margin-bottom: 60px;
            .price-vat,
            .price-rrp {
                color: $dark-grey;
                @include font-size(11px);
                font-family: $primary-font;
                line-height: normal;
            }
            .rrp {
                color: $red;
            }
        }
        #pdp-product-price {
            @include font-size(28px);
            font-weight: 500;
            color: $black;
            margin-bottom: 13px;
            padding-top: 6px;
            min-height: 30px;
        }
        #pdp-stock-basket {
            .add-to-basket {
                margin-bottom: 10px;
                justify-content: flex-start !important;
                .btn-disabled {
                    padding: 8px 10px;
                }
                .btn-loading {
                    width: 200px;
                }
                .quantity-selector {
                    width: 200px;
                    height: 46px;
                    border-radius: 23px;
                    .btn-spinner {
                        padding: 8px 36px;
                    }
                    input {
                        height: 46px;
                    }
                }
            }
        }
        .btn-add-to-cart {
            background: $red;
            border: 1px solid $red;
            color: $white;
        }
        .pdp-info {
            h1 {
                @include font-size(26px);
                line-height: 32px;
            }
            .identifier {
                color: $red;
                @include font-size(15px);
                margin-bottom: 60px;
                display: block;
            }
            .information {
                border-radius: $radius8;
                background: $light-grey;
                padding: 20px;
                .brand-image {
                    img {
                        max-height: 90px;
                        max-width: 350px;
                        width: auto;
                        height: auto;
                    }
                }
                .teaser {
                    margin: 12px 0 0;
                }
                .js-scroll-to-link {
                    @include font-size(13px);
                    display: block;
                    margin: 12px 0 0;
                }
            }
        }

        .pdp-images {
            .gallery-pdp {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                a {
                    height: 100%;
                }
            }
        }

        // LOADER
        .stock,
        .price {
            &.loading:not(.no-price) {
                @extend .skeletonLoader;
                width: 200px;
            }
        }

        .price {
            #pdp-product-price {
                line-height: 34px;
            }
            &.loading {
                min-height: 30px;
                .price-vat,
                .price-rrp {
                    display: none;
                }
            }
        }

        .stock {
            &.loading {
                min-height: 25px;
            }
        }
    }

    @include devices(tablet) {
        .item-detail {
            .pdp-price {
                margin-bottom: 30px;
                h5 {
                    line-height: 27px;
                }
                .price-rrp,
                .price-vat {
                    display: block;
                }
            }
            .pdp-info {
                h1 {
                    @include font-size(22px);
                }
                .identifier {
                    margin-bottom: 30px;
                }
            }
            .pdp-images {
                margin-bottom: 30px;
            }
        }
    }
}
